import React, { FC } from "react";
import classNames from "classnames";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// components

import TypeSelect from "components/Content/TypeSelect";

//styles

import styles from "assets/jss/nextjs-material-kit/components/badgeStyle";
import customStyles from "assets/jss/custom/customStyles";
import { Grid } from "@material-ui/core";
import { useRouter } from "next/dist/client/router";

import Button from "components/CustomButtons/Button";
import { successColor } from "assets/jss/nextjs-material-kit";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
});

const CTABlock: FC = () => {
  const classes = useStyles();
  const router = useRouter();

  const [type, setType] = React.useState<"0" | "1">("0");

  return (
    <div
      className={classNames(classes.hasBorderRadius, classes.hasMarginTop10)}
    >
      <div className={classes.container}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          alignContent="center"
        >
          <Grid item xs={12} sm={4}>
            <div className={classes.isWhite}>
              <TypeSelect setType={setType} type={type} variant="highlighted" />
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              style={{ backgroundColor: successColor }}
              fullWidth
              onClick={() => router.push("/register?type=" + type)}
            >
              jetzt kostenlos flirten
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default CTABlock;
