import { container, title } from "assets/jss/nextjs-material-kit.js";
import { createStyles } from "@material-ui/core/styles";

const landingPageStyle = createStyles({
  container: {
    fontFamily: "'Fira Sans', 'sans-serif'",
    color: "#FFFFFF",
    paddingBottom: "5vw",
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "14vw",
    marginBottom: "60px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    fontWeight: "bold",
    textAlign: "center",
    fonWeight: 800,
    textShadow: "0 0 20px rgba(0, 0, 0, 0.8)",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
});

export default landingPageStyle;
